"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", [_vm.isLoading ? _c("van-loading", {
    staticClass: "page-loading"
  }) : _c("div", {
    staticClass: "policy-container"
  }, [_c("detail-content", {
    attrs: {
      detail: _vm.detail
    }
  }), _c("div", {
    staticClass: "other-info-wrap"
  }, [_c("hr"), _vm._l(_vm.otherInfo, function (item) {
    return _c("div", {
      key: item.source,
      staticClass: "field-item"
    }, [item.type === "file" ? _c("div", [_vm.detail[item.has] ? _c("div", _vm._l(_vm.detail[item.fieldName].split(",").filter(file => file), function (file, index) {
      return _c("p", {
        key: index
      }, [_c("label", {
        staticClass: "field-label"
      }, [_vm._v(_vm._s(item.label) + "：")]), _c("span", {
        staticClass: "file-url field-value",
        on: {
          click: function ($event) {
            return _vm.downLoadFile(file);
          }
        }
      }, [_vm._v(_vm._s(_vm.getFileName(file)))])]);
    }), 0) : _vm._e()]) : item.type === "amount" ? _c("div", [_vm.isNotEmpty(_vm.detail[item.max]) ? _c("p", [_c("label", {
      staticClass: "field-label"
    }, [_vm._v(_vm._s(item.label) + "：")]), _c("span", {
      staticClass: "field-value"
    }, [_vm._v(_vm._s(`${_vm.detail[item.min]}-${_vm.detail[item.max]}万元`))])]) : _vm._e()]) : _c("div", [_vm.isNotEmpty(_vm.detail[item.fieldName]) ? _c("p", [_c("label", {
      staticClass: "field-label"
    }, [_vm._v(_vm._s(item.label) + "：")]), _c("span", {
      staticClass: "field-value"
    }, [_vm._v(_vm._s(_vm.detail[item.fieldName]))])]) : _vm._e()])]);
  }), _vm.isWxMp ? _c("div", {
    staticClass: "operation-wrap"
  }, [_vm.resourceList.includes("companyConsult") ? _c("van-button", {
    staticClass: "opt-btn",
    attrs: {
      type: "default"
    },
    on: {
      click: function ($event) {
        return _vm.linkMpPage("/pages/polConsult/polConsult");
      }
    }
  }, [_vm._v("我要咨询")]) : _vm._e(), _vm.detail.type == 2 && _vm.resourceList.includes("policyAudit") ? _c("van-button", {
    staticClass: "opt-btn",
    attrs: {
      type: "default"
    },
    on: {
      click: function ($event) {
        return _vm.linkMpPage("/pages/policyAudit/policyAudit");
      }
    }
  }, [_vm._v("资料审核")]) : _vm._e()], 1) : _vm._e()], 2)], 1), _c("scroll-top")], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;