"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "layout-content"
  }, [_vm.$route.meta.keepAlive ? _c("keep-alive", [_c("router-view")], 1) : _c("router-view")], 1)]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;