"use strict";

var _interopRequireDefault = require("/root/workspace/saas-share_test/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

require("core-js/stable");

require("regenerator-runtime/runtime");

var _vue = _interopRequireDefault(require("vue"));

var _App = _interopRequireDefault(require("./App.vue"));

var _router = _interopRequireDefault(require("./router"));

var _store = _interopRequireDefault(require("./store"));

var _control = _interopRequireDefault(require("./api/control"));

var _weixinJsSdk = _interopRequireDefault(require("weixin-js-sdk"));

var _config = require("@/config");

require("@/plugins/vant");

require("@/assets/css/index.scss");

require("./filters");

// 兼容 IE
// https://github.com/zloirock/core-js/blob/master/docs/2019-03-19-core-js-3-babel-and-a-look-into-the-future.md#babelpolyfill
_vue.default.prototype.$wx = _weixinJsSdk.default; // 设置 js中可以访问 $cdn

_vue.default.prototype.$cdn = _config.$cdn; // 全局引入按需引入UI库 vant

_vue.default.config.productionTip = false;
_vue.default.prototype.$Apis = _control.default;
new _vue.default({
  el: '#app',
  router: _router.default,
  store: _store.default,
  render: h => h(_App.default)
});