"use strict";

var _interopRequireDefault = require("/root/workspace/saas-share_test/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _api = _interopRequireDefault(require("./api.js"));

var _request = _interopRequireDefault(require("../utils/request"));

const getInformationDetail = (params = {}) => {
  return _request.default.get(_api.default.getInformationDetail, {
    params
  });
};

const getPolicyDetail = (params = {}) => {
  return _request.default.get(_api.default.getPolicyDetail, {
    params
  });
};

const getThirdServiceDetail = (params = {}) => {
  return _request.default.get(_api.default.getThirdServiceDetail, {
    params
  });
};

const getWechatConfig = (params = {}) => {
  return _request.default.get(_api.default.getWechatConfig, {
    params
  });
};

const getResource = (params = {}) => {
  return _request.default.get(_api.default.getResource, {
    params
  });
};
/*
const setXX = (params = {}) => {
  return request.post(api.XX, params)
}
*/


var _default = {
  getInformationDetail,
  getPolicyDetail,
  getThirdServiceDetail,
  getWechatConfig,
  getResource
};
exports.default = _default;