"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const getters = {
  userName: state => state.app.userName
};
var _default = getters;
exports.default = _default;