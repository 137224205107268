"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const state = {
  userName: ''
};
const mutations = {
  SET_USER_NAME(state, name) {
    state.userName = name;
  }

};
const actions = {
  // 设置name
  setUserName({
    commit
  }, name) {
    commit('SET_USER_NAME', name);
  }

};
var _default = {
  state,
  mutations,
  actions
};
exports.default = _default;