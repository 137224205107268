"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  data() {
    return {
      show: false
    };
  },

  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },

  methods: {
    backTop() {
      document.querySelector('#app').scrollIntoView({
        behavior: 'smooth'
      });
    },

    handleScroll() {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset;

      if (scrollTop > 100) {
        this.show = true;
      } else {
        this.show = false;
      }
    }

  }
};
exports.default = _default;