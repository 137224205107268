"use strict";

var _interopRequireDefault = require("/root/workspace/saas-share_test/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.constantRouterMap = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/saas-share_test/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));

/**
 * 基础路由
 * @type { *[] }
 */
const constantRouterMap = [{
  path: '/',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/layouts/index'))),
  meta: {
    title: '首页',
    keepAlive: false
  },
  children: [{
    path: '/newsDetail',
    name: 'newsDetail',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/newsDetail/index'))),
    meta: {
      title: '新闻资讯',
      keepAlive: false
    }
  }, {
    path: '/noticeDetail',
    name: 'noticeDetail',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/noticeDetail/index'))),
    meta: {
      title: '通知公告',
      keepAlive: false
    }
  }, {
    path: '/policyDetail',
    name: 'policyDetail',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/policyDetail/index'))),
    meta: {
      title: '政策解读',
      keepAlive: false
    }
  }, {
    path: '/serviceDetail',
    name: 'serviceDetail',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/serviceDetail/index'))),
    meta: {
      title: '第三方服务',
      keepAlive: false
    }
  }]
}];
exports.constantRouterMap = constantRouterMap;