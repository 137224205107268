"use strict";

var _interopRequireDefault = require("/root/workspace/saas-share_test/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _vue = _interopRequireDefault(require("vue"));

var _vuex = _interopRequireDefault(require("vuex"));

var _getters = _interopRequireDefault(require("./getters"));

var _app = _interopRequireDefault(require("./modules/app"));

_vue.default.use(_vuex.default);

const store = new _vuex.default.Store({
  modules: {
    app: _app.default
  },
  getters: _getters.default
});
var _default = store;
exports.default = _default;