"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _utils = require("../utils");

var _default = {
  props: {
    detail: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {};
  },

  created() {
    this.isNotEmpty = _utils.isNotEmpty;
  },

  methods: {}
};
exports.default = _default;