"use strict";

var _interopRequireDefault = require("/root/workspace/saas-share_test/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("vant/es/dialog/style");

var _dialog = _interopRequireDefault(require("vant/es/dialog"));

require("vant/es/image-preview/style");

var _imagePreview = _interopRequireDefault(require("vant/es/image-preview"));

require("vant/es/toast/style");

var _toast = _interopRequireDefault(require("vant/es/toast"));

var _detailContent = _interopRequireDefault(require("../components/detailContent.vue"));

var _scrollTop = _interopRequireDefault(require("../components/scrollTop.vue"));

var _index = require("../utils/index");

//import VConsole from 'vconsole';
const fileTypes = ['doc', 'docx', 'xls', 'xlsx', 'pdf'];
var _default = {
  components: {
    DetailContent: _detailContent.default,
    ScrollTop: _scrollTop.default
  },

  data() {
    return {
      isLoading: false,
      isWxMp: false
    };
  },

  computed: {},
  watch: {
    detail(data) {
      if (this.$wx) {
        this.$wx.ready(() => {
          const {
            title,
            summary: desc,
            img: imgUrl
          } = data; // 图片基本处理 ?imageView2/2/w/360

          if (title) {
            const params = {
              title: decodeURIComponent(title),
              desc,
              imgUrl: `${imgUrl}?imageView2/2/w/360`,
              link: window.location.href,
              success: function () {}
            }; // 分享给朋友”及“分享到QQ”

            this.$wx.updateAppMessageShareData(params); // 分享到朋友圈”及“分享到 QQ 空间”

            this.$wx.updateTimelineShareData(params);
          }
        });
        this.$wx.error(function (error) {
          console.log('wx config error', error);
        });
      }
    }

  },

  created() {
    if (_index.isWx && !_index.isWxMp) {
      this.getWxConfig();
    }

    this.isWxMp = _index.isWxMp; //const vConsole = new VConsole();
  },

  methods: {
    async getWxConfig() {
      const data = await this.$Apis.getWechatConfig({
        url: encodeURI(window.location.href.split("#")[0])
      });

      if (data) {
        this.$wx.config({
          debug: false,
          appId: data.appId,
          timestamp: data.timestamp,
          nonceStr: data.nonceStr,
          signature: data.signature,
          jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData', 'onMenuShareAppMessage', 'onMenuShareTimeline']
        });
      } else {
        _toast.default.fail('获取微信配置失败');
      }
    },

    init() {
      const _this = this;

      this.$nextTick(() => {
        document.querySelector(".content").addEventListener('click', e => {
          const target = e.target;
          const tagName = target.tagName.toLowerCase();

          if (tagName === 'img') {
            _this._prevImage(target);
          } else if (tagName === 'a') {
            const fileType = (0, _index.getFileType)(target.href); // android 手机微信小程序内文件链接点击没有反应
            // 外部链接打不开
            // 提示在浏览器中打开

            if (fileTypes.includes(fileType) && _index.isAndroid && _index.isWxMp || !fileTypes.includes(fileType)) {
              if (!fileTypes.includes(fileType)) {
                e.preventDefault();
              }

              _this._handleUploadFile(target);
            }
          }
        });
      });
    },

    _prevImage(image) {
      const imgs = Array.from(document.querySelectorAll(".content img"));
      const imgUrls = imgs.filter(img => img.src).map(img => img.src);
      const index = imgUrls.findIndex(item => item === image.src);
      (0, _imagePreview.default)({
        images: imgUrls,
        startPosition: index
      });
    },

    _handleUploadFile(target) {
      _dialog.default.confirm({
        title: '提示',
        message: '复制链接，在浏览器中打开'
      }).then(() => {
        (0, _index.copyContent)(target.href);
      }).catch(() => {});
    }

  }
};
exports.default = _default;