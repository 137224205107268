"use strict";

var _interopRequireWildcard = require("/root/workspace/saas-share_test/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;

var _interopRequireDefault = require("/root/workspace/saas-share_test/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

var _vue = _interopRequireDefault(require("vue"));

var filter = _interopRequireWildcard(require("./filter"));

Object.keys(filter).forEach(k => _vue.default.filter(k, filter[k]));
_vue.default.prototype.$formatDate = _vue.default.filter('formatDate');
_vue.default.prototype.$hidePhone = _vue.default.filter('hidePhone');