"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.copyContent = copyContent;
exports.formatTime = formatTime;
exports.getFileType = getFileType;
exports.isAndroid = void 0;
exports.isNotEmpty = isNotEmpty;
exports.isWxMp = exports.isWx = void 0;
exports.param2Obj = param2Obj;
exports.parseTime = parseTime;

/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string}
 */
function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }

  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  let date;

  if (typeof time === 'object') {
    date = time;
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }

    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }

    date = new Date(time);
  }

  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]; // Note: getDay() returns 0 on Sunday

    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }

    if (result.length > 0 && value < 10) {
      value = '0' + value;
    }

    return value || 0;
  });
  return time_str;
}
/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */


function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }

  const d = new Date(time);
  const now = Date.now();
  const diff = (now - d) / 1000;

  if (diff < 30) {
    return '刚刚';
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前';
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前';
  } else if (diff < 3600 * 24 * 2) {
    return '1天前';
  }

  if (option) {
    return parseTime(time, option);
  } else {
    return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分';
  }
}
/**
 * @param {string} url
 * @returns {Object}
 */


function param2Obj(url) {
  const search = url.split('?')[1];

  if (!search) {
    return {};
  }

  return JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"').replace(/\+/g, ' ') + '"}');
}

function isNotEmpty(value) {
  return value && value !== '--';
}

const ua = navigator.userAgent.toLowerCase();
const isAndroid = ua.indexOf('android') > -1;
exports.isAndroid = isAndroid;
const isWxMp = ua.indexOf("miniprogram") > -1;
/* 微信小程序 */

exports.isWxMp = isWxMp;
const isWx = ua.indexOf("micromessenger") > -1;
/* 微信公众号 微信小程序 */
// 获取文件扩展名

exports.isWx = isWx;

function getFileType(fileUrl) {
  const filePath = fileUrl.includes('?') ? fileUrl.split('?')[0] : fileUrl;
  const fileType = filePath.substring(filePath.lastIndexOf('.') + 1);
  return fileType.toLowerCase();
}

function copyContent(content) {
  const copyDom = document.createElement('div');
  copyDom.innerText = content;
  copyDom.style.position = 'absolute';
  copyDom.style.top = '0px';
  copyDom.style.right = '-9999px';
  document.body.appendChild(copyDom); //创建选中范围

  let range = document.createRange();
  range.selectNode(copyDom); //移除剪切板中内容

  window.getSelection().removeAllRanges(); //添加新的内容到剪切板

  window.getSelection().addRange(range); //复制

  const successful = document.execCommand('copy');
  copyDom.parentNode.removeChild(copyDom);

  if (!successful) {
    console.log('copy faile');
  }
}