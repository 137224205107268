"use strict";

var _interopRequireDefault = require("/root/workspace/saas-share_test/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _detail = _interopRequireDefault(require("../../mixins/detail"));

var _scrollTop = _interopRequireDefault(require("@/components/scrollTop.vue"));

var _default = {
  components: {
    scrollTop: _scrollTop.default
  },
  name: 'newsDetail',
  mixins: [_detail.default],

  data() {
    return {
      detail: {}
    };
  },

  created() {
    this.getDetail();
  },

  mounted() {
    document.title = '新闻资讯';
  },

  methods: {
    async getDetail() {
      const {
        id
      } = this.$route.query;

      if (id) {
        this.isLoading = true;
        const data = await this.$Apis.getInformationDetail({
          forEdit: false,
          id
        });

        if (data) {
          const {
            source,
            totalReadCount,
            createTime,
            summary,
            content,
            title,
            img
          } = data;
          this.detail = {
            source,
            totalReadCount,
            createTime,
            summary,
            content,
            title,
            img
          };
        }

        this.isLoading = false;
        this.init();
      }

      ;
    }

  }
};
exports.default = _default;