"use strict";

var _interopRequireDefault = require("/root/workspace/saas-share_test/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("vant/es/toast/style");

var _toast = _interopRequireDefault(require("vant/es/toast"));

var _axios = _interopRequireDefault(require("axios"));

var _store = _interopRequireDefault(require("../store"));

var _jsMd = _interopRequireDefault(require("js-md5"));

var _config = require("@/config");

// 根据环境不同引入不同baseApi地址
const host = _config.baseApi || window.location.host;
const api = host.substring(host.indexOf('.') + 1, host.length);
_axios.default.defaults.baseURL = `${location.protocol}//api.${api}`; // http request 请求拦截器

_axios.default.interceptors.request.use(config => {
  let appkey = 'aOvLN3QRg8*G0%i#M$TG6jZfzXH!2XWF';
  let cid = 'h5wap'; //客户端：ios-苹果手机、 android-安卓手机、 ucweb-pc端eb页面、h5wap-手机端eb页面、 wechat-微信公众号、 wxapplet-微信小程序、 alipayapplet-支付宝小程序

  let cv = '1.0.0'; //客户端版本号
  //因后台无法检查对象值得类型，所以所有值都转成字符串

  let url = config.url || '';
  let dataStr = '';

  if (config.method == 'post' || config.method == 'POST') {
    dataStr = JSON.stringify(config.data);
  } else {
    let param = config.params;
    var b = JSON.stringify(param) === '{}';
    let newParams = {};

    if (!b) {
      let datap = null;
      const gp = {};
      Object.keys(param).sort().map(key => {
        //排序
        newParams[key] = param[key];
      });

      for (var p in newParams) {
        if (datap) {
          datap = datap + '&' + p + '=' + newParams[p];
        } else {
          datap = p + '=' + newParams[p];
        }

        gp[p] = newParams[p] + '';
      }

      if (datap) {
        newParams = gp;
      }
    }

    config.params = newParams;
    dataStr = JSON.stringify(newParams);
  }

  let dataMd5 = (0, _jsMd.default)(dataStr).toLocaleUpperCase() || ''; //md5

  let ts = `${new Date().getTime()}`; //时间戳

  let sign = ['AK', appkey, 'cid', cid, 'cv', cv, 'data', dataMd5, 'ts', ts, 'url', url];
  config.headers['cid'] = cid;
  config.headers['cv'] = cv;
  config.headers['ts'] = ts;
  config.headers['sign'] = (0, _jsMd.default)(`${sign.join('')}`); //md5

  config.headers['gat'] = '';
  return config;
}, error => {
  // 对请求错误做些什么
  return Promise.reject(error);
});

_axios.default.interceptors.response.use(response => {
  //  const{status}=response;
  const {
    message,
    success,
    code,
    data
  } = response.data;

  if (!success) {
    if (message.indexOf('异常') === -1 && message.indexOf('com') === -1 && message.length < 50) {
      _toast.default.fail(message);
    } else {
      _toast.default.fail("请稍候再试");
    }

    return Promise.reject();
  }

  return data;
}, async error => {}, error => {
  console.log(error);
  return Promise.reject();
});

var _default = _axios.default;
exports.default = _default;