"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "content-container"
  }, [_c("h1", {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.detail.title))]), _c("div", {
    staticClass: "media"
  }, [_vm.isNotEmpty(_vm.detail.totalReadCount) ? _c("span", {
    staticClass: "view-cnt"
  }, [_vm._v(" 阅读量：" + _vm._s(_vm.detail.totalReadCount) + " ")]) : _vm._e(), _c("span", {
    staticClass: "create-time"
  }, [_vm._v(_vm._s(_vm.detail.createTime))])]), _vm.isNotEmpty(_vm.detail.summary) ? _c("div", {
    staticClass: "summary"
  }, [_vm._v(_vm._s(_vm.detail.summary))]) : _vm._e(), _c("div", {
    staticClass: "content",
    domProps: {
      innerHTML: _vm._s(_vm.detail.content)
    }
  }), _vm.isNotEmpty(_vm.detail.source) ? _c("p", {
    staticClass: "source"
  }, [_c("img", {
    staticClass: "source-icon",
    attrs: {
      src: require("@/assets/imgs/source.png"),
      alt: "来源"
    }
  }), _vm._v(" 来源：" + _vm._s(_vm.detail.source) + " ")]) : _vm._e()]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;