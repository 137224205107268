"use strict";

var _interopRequireDefault = require("/root/workspace/saas-share_test/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _detail = _interopRequireDefault(require("../../mixins/detail"));

var _scrollTop = _interopRequireDefault(require("@/components/scrollTop.vue"));

var _default = {
  components: {
    scrollTop: _scrollTop.default
  },
  name: 'serviceDetail',
  mixins: [_detail.default],

  data() {
    return {
      detail: {},
      resourceList: []
    };
  },

  created() {
    this.getDetail();
    this.getResource();
  },

  mounted() {
    document.title = '第三方服务';
  },

  methods: {
    async getDetail() {
      const {
        id
      } = this.$route.query;

      if (id) {
        this.isLoading = true;
        const data = await this.$Apis.getThirdServiceDetail({
          id
        });

        if (data) {
          const {
            viewCount,
            publishTime,
            introduction,
            content,
            title,
            thumbnail,
            id
          } = data;
          this.detail = {
            content,
            title,
            id,
            totalReadCount: viewCount,
            img: thumbnail,
            createTime: publishTime,
            summary: introduction
          };
        }

        this.isLoading = false;
        this.init();
      }

      ;
    },

    getResource() {
      // 小程序
      this.$Apis.getResource({
        sysType: 6
      }).then(res => {
        this.resourceList = res.btnMap['service/detail'] || [];
      });
    },

    linkMpPage(path) {
      this.$wx.miniProgram.navigateTo({
        url: `${path}?id=${this.detail.id}`
      });
    }

  }
};
exports.default = _default;