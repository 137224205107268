"use strict";

var _interopRequireDefault = require("/root/workspace/saas-share_test/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _detail = _interopRequireDefault(require("../../mixins/detail"));

var _utils = require("../../utils");

var _scrollTop = _interopRequireDefault(require("@/components/scrollTop.vue"));

var _default = {
  components: {
    scrollTop: _scrollTop.default
  },
  name: 'policyDetail',
  mixins: [_detail.default],

  data() {
    return {
      detail: {},
      otherInfo: [{
        label: '政策级别',
        fieldName: 'policyLevelName'
      }, {
        label: '政策起始时间',
        fieldName: 'declareFullTime'
      }, {
        label: '政策原件',
        fieldName: 'originFileUrl',
        has: 'hasOriginFile',
        type: 'file'
      }, {
        label: '补助金额',
        min: 'subsidyAmountsMin',
        max: 'subsidyAmountsMax',
        type: 'amount'
      }],
      resourceList: []
    };
  },

  created() {
    this.isNotEmpty = _utils.isNotEmpty;
    this.getDetail();
    this.getResource();
  },

  mounted() {
    document.title = '政策解读';
  },

  methods: {
    async getDetail() {
      const {
        id,
        cid
      } = this.$route.query;

      if (id) {
        this.isLoading = true;
        this.$Apis.getPolicyDetail({
          forEdit: false,
          id,
          companyId: cid || ''
        }).then(data => {
          if (data) {
            const {
              source,
              totalReadCount,
              createTime,
              type,
              id,
              summary,
              content,
              name,
              img,
              policyLevelName,
              declareFullTime,
              hasOriginFile,
              originFileUrl,
              subsidyAmountsMin,
              subsidyAmountsMax
            } = data;
            this.detail = {
              source,
              totalReadCount,
              createTime,
              summary,
              content,
              img,
              policyLevelName,
              declareFullTime,
              hasOriginFile,
              originFileUrl,
              subsidyAmountsMax,
              subsidyAmountsMin,
              title: name,
              type,
              id
            };
          }

          this.isLoading = false;
          this.init();
        });
      }

      ;
    },

    getResource() {
      // 小程序
      this.$Apis.getResource({
        sysType: 6
      }).then(res => {
        this.resourceList = res.btnMap['policy/detail'] || [];
      });
    },

    getFileName(fileUrl) {
      return fileUrl.split('/').pop();
    },

    downLoadFile(fileUrl) {
      window.open(fileUrl, '_self');
    },

    linkMpPage(path) {
      this.$wx.miniProgram.navigateTo({
        url: `${path}?id=${this.detail.id}`
      });
    }

  }
};
exports.default = _default;