"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'AppLayout',

  data() {
    return {};
  }

};
exports.default = _default;