"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", [_vm.isLoading ? _c("van-loading", {
    staticClass: "page-loading"
  }) : _c("detail-content", {
    attrs: {
      detail: _vm.detail
    }
  }), _c("scroll-top")], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;