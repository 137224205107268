"use strict";

var _interopRequireDefault = require("/root/workspace/saas-share_test/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

require("vant/es/button/style");

var _button = _interopRequireDefault(require("vant/es/button"));

var _vue = _interopRequireDefault(require("vue"));

// 按需全局引入 vant组件
_vue.default.use(_button.default);