"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", [_vm.isLoading ? _c("van-loading", {
    staticClass: "page-loading"
  }) : _c("div", {
    staticClass: "service-wrap"
  }, [_c("detail-content", {
    attrs: {
      detail: _vm.detail
    }
  }), _vm.isWxMp ? _c("div", {
    staticClass: "operation-wrap"
  }, [_vm.resourceList.includes("companyConsult") ? _c("van-button", {
    staticClass: "opt-btn",
    attrs: {
      type: "default"
    },
    on: {
      click: function ($event) {
        return _vm.linkMpPage("/pages/serConsult/serConsult");
      }
    }
  }, [_vm._v("我要咨询")]) : _vm._e()], 1) : _vm._e()], 1), _c("scroll-top")], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;