"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const api = {
  getInformationDetail: '/api/front/information/detail',
  getPolicyDetail: '/api/front/policy/detail',
  getThirdServiceDetail: '/api/front/thirdService/detail',
  getWechatConfig: '/api/common/wechat/jsapi/config/info',
  getResource: '/api/common/user/getSysResource'
};
var _default = api;
exports.default = _default;