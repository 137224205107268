"use strict";

var _interopRequireDefault = require("/root/workspace/saas-share_test/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
exports.resetRouter = resetRouter;

var _vue = _interopRequireDefault(require("vue"));

var _vueRouter = _interopRequireDefault(require("vue-router"));

var _routerConfig = require("./router.config.js");

// hack router push callback
const originalPush = _vueRouter.default.prototype.push;

_vueRouter.default.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};

_vue.default.use(_vueRouter.default);

const createRouter = () => new _vueRouter.default({
  // mode: 'history', // 如果你是 history模式 需要配置vue.config.js publicPath
  // base: process.env.BASE_URL,
  scrollBehavior: () => ({
    y: 0
  }),
  routes: _routerConfig.constantRouterMap
});

const router = createRouter(); // Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465

function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

var _default = router;
exports.default = _default;